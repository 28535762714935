import { useState, FormEvent, memo, ChangeEvent, useEffect } from "react";
import { KcProps, KcContextBase } from "keycloakify";
import Template from "../Template";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useConstCallback } from "powerhooks";
import { LoadingButton } from "@mui/lab";
import { SocialLoginButton } from "../SocialLoginButton";
import { PasswordTextField } from "../PasswordTextField";
import { ALLIANCE_CONFIG } from "constants/scopeMapping";
import { I18n, useI18n } from "i18n";

export const Login = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContextBase.Login; i18n: I18n } & KcProps) => {
    const { msg, msgStr } = i18n;
    const { social, realm, url, usernameEditDisabled, login } = kcContext;
    const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
    const [values, setValues] = useState({
      username: "",
      password: "",
      rememberMe: false,
    });
    useEffect(() => {
      if (document?.location && values.username === "") {
        const redirect_uri = new URL(
          document.location.toString()
        ).searchParams.get("redirect_uri");
        if (redirect_uri) {
          const prefilledEmail =
            new URL(redirect_uri).searchParams.get("email") || "";
          setValues({
            ...values,
            username: prefilledEmail,
          });
        }
      }
    }, []);
    const onSubmit = useConstCallback((event: FormEvent<HTMLFormElement>) => {
      setIsLoginButtonDisabled(true);
      return true;
    });
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    const scope = localStorage.getItem("scope");
    const isScopePresented =
      scope && Object.keys(ALLIANCE_CONFIG).includes(scope);
    const allianceInputsConfig = isScopePresented
      ? ALLIANCE_CONFIG[scope as keyof typeof ALLIANCE_CONFIG].inputsConfig
      : {
          background: "white",
          borderRadius: "4px",
          color: "black",
          "&:hover": {
            borderColor: "black",
          },
          WebkitTextFillColor: "black",
        };
    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={false}
        displayInfo={social.displayInfo}
        displayWide={realm.password && social.providers !== undefined}
        headerNode={"Sign in to your account"}
        formNode={
          <Box
            component="form"
            id="kc-form-login"
            action={url.loginAction}
            onSubmit={onSubmit}
            method="post">
            <Stack spacing={2}>
              <Box>
                <TextField
                  id="username"
                  name="username"
                  defaultValue={values.username || login.username || ""}
                  label={
                    !realm.loginWithEmailAllowed
                      ? msg("username")
                      : !realm.registrationEmailAsUsername
                      ? msg("usernameOrEmail")
                      : msg("email")
                  }
                  onChange={onChange}
                  autoComplete="email"
                  margin="normal"
                  type="text"
                  fullWidth
                  autoFocus
                  required
                  placeholder="Email"
                  InputLabelProps={{
                    style: {
                      color: isScopePresented ? "#fff" : "black",
                      fontSize: "18px",
                    },
                  }}
                  InputProps={{ style: allianceInputsConfig }}
                />

                {/* Password */}
                <PasswordTextField
                  pwd={values.password}
                  onPasswordChange={onChange}
                  placeholder="Password"
                  InputLabelProps={{
                    style: {
                      color: isScopePresented ? "#fff" : "black",
                      fontSize: "18px",
                    },
                  }}
                  InputProps={{ style: allianceInputsConfig }}
                />

                <Box
                  display="flex"
                  justifyContent={
                    realm.rememberMe && !usernameEditDisabled
                      ? "space-between"
                      : "end"
                  }
                  alignItems="center">
                  {/* Remember Me */}
                  {realm.rememberMe && !usernameEditDisabled && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="rememberMe"
                          name="rememberMe"
                          color="secondary"
                          onChange={onChange}
                          value="rememberMe"
                          style={{
                            color: isScopePresented ? "#fff" : "black",
                          }}
                        />
                      }
                      label={msg("rememberMe")}
                    />
                  )}

                  {/* Forgot Password */}
                  {realm.resetPasswordAllowed && (
                    <Box display="flex" justifyContent="center">
                      <Link
                        href={url.loginResetCredentialsUrl}
                        underline="none"
                        color={isScopePresented ? "#fff" : "black"}>
                        {msgStr("doForgotPassword")}
                      </Link>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Login Button */}
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={isLoginButtonDisabled}
                disabled={isLoginButtonDisabled}
                style={{
                  color: "#fff",
                  textTransform: "unset",
                  fontWeight: "600",
                  fontSize: "18px",
                  height: "48px",
                }}>
                Sign in
              </LoadingButton>
            </Stack>
            {social.providers?.map((provider) => {
              return (
                <>
                  <Divider sx={{ marginTop: "30px", marginBottom: "30px" }}>
                    <Typography>or</Typography>
                  </Divider>
                  <SocialLoginButton provider={provider} />
                </>
              );
            })}
          </Box>
        }
      />
    );
  }
);
