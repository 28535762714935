import { GoogleLoginButton } from "react-social-login-buttons";

export type ProviderProps = {
  loginUrl: string;
  alias: string;
  providerId: string;
  displayName: string;
};

export const SocialLoginButton = ({
  provider,
}: {
  provider: ProviderProps;
}) => {
  const { loginUrl } = provider;

  return (
    <GoogleLoginButton
      style={{
        borderRadius: "10px",
        fontFamily: "Roboto",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "500",
        color: "rgba(0, 0, 0, 0.54)",
      }}
      align="center"
      text="Sign in with Google"
      
      onClick={() => (window.location.href = loginUrl)}
    />
  );
};
