import dallasLogo from "../assets/images/dallas-logo.svg";
import sanDiegoLogo from "../assets/images/san-diego-logo.png";
import summitLogo from "../assets/images/summit-logo.svg";
import steamboatLogo from "../assets/images/steamboat-logo.svg";
import azrtLogo from "../assets/images/azrt-logo.svg";
export const DALLAS_SCOPE = "dallas";
export const DALLAS_SCOPE_DEV = "rr-dev-dallas";
export const SANDIEGO_SCOPE = "sandiego";
export const SANDIEGO_SCOPE_DEV = "rr-dev-sandiego";
export const SUMMIT_SCOPE = "summit-co";
export const SUMMIT_SCOPE_DEV = "rr-dev-summit";
export const STEAMBOATSPRINGS_SCOPE = "steamboatsprings-co";
export const STEAMBOATSPRINGS_SCOPE_DEV = "rr-dev-steamboatsprings-co";
export const AZRT_SCOPE = "azrt";
export const AZRT_SCOPE_DEV = "rr-dev-azrt";

export const ALLIANCE_CONFIG = {
  [DALLAS_SCOPE]: {
    backgroundConfig: {
      background:
        "linear-gradient(rgba(5, 52, 95, 0.5), rgba(5, 52, 95, 0.5)), url(https://res.cloudinary.com/divo9kdb3/image/upload/v1656925971/backgrounds/image_90_1_wf3vpy.png) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(147, 164, 189, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: dallasLogo, width: "140px" },
    mainColor: "#51A2EC",
    membershipUrl: "https://dallasstra.org/membership",
    allianceName: "Dallas STRA",
  },
  [DALLAS_SCOPE_DEV]: {
    backgroundConfig: {
      background:
        "linear-gradient(rgba(5, 52, 95, 0.5), rgba(5, 52, 95, 0.5)), url(https://res.cloudinary.com/divo9kdb3/image/upload/v1656925971/backgrounds/image_90_1_wf3vpy.png) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(147, 164, 189, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: dallasLogo, width: "140px" },
    mainColor: "#51A2EC",
    membershipUrl: "https://dallasstra.org/membership",
    allianceName: "Dallas STRA",
  },
  [SANDIEGO_SCOPE]: {
    backgroundConfig: {
      background:
        "linear-gradient(rgba(4,93,113,0.73), rgba(4,93,113,0.73)), url(https://sandiegostra.org/wp-content/uploads/2021/09/san-diego-aerial-homes-scaled.jpeg) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(147, 164, 189, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: sanDiegoLogo, width: "140px" },
    mainColor: "#0b9db8",
    membershipUrl: "https://sandiegostra.org/membership",
    allianceName: "San Diego STRA",
  },
  [SANDIEGO_SCOPE_DEV]: {
    backgroundConfig: {
      background:
        "linear-gradient(rgba(4,93,113,0.73), rgba(4,93,113,0.73)), url(https://sandiegostra.org/wp-content/uploads/2021/09/san-diego-aerial-homes-scaled.jpeg) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(147, 164, 189, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: sanDiegoLogo, width: "140px" },
    mainColor: "#0b9db8",
    membershipUrl: "https://sandiegostra.org/membership",
    allianceName: "San Diego STRA",
  },
  [SUMMIT_SCOPE_DEV]: {
    backgroundConfig: {
      background:
        "linear-gradient(180deg, rgba(120, 129, 137, 0) 0%, rgba(77,77,77, 0.7)100%), url(https://assets.website-files.com/60622e760aca084e01e5e1e6/6067507eea324fe908f98ae3_andrea-stark-hJLWQHBybpQ-unsplash.jpg) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(120, 129, 137, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: summitLogo, width: "140px" },
    mainColor: "#4D4D4D",
    membershipUrl: "https://payment.savrm.org/",
    allianceName: "Summit STRA",
  },
  [SUMMIT_SCOPE]: {
    backgroundConfig: {
      background:
        "linear-gradient(180deg, rgba(120, 129, 137, 0) 0%, rgba(77,77,77, 0.8) 100%), url(https://assets.website-files.com/60622e760aca084e01e5e1e6/6067507eea324fe908f98ae3_andrea-stark-hJLWQHBybpQ-unsplash.jpg) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(120, 129, 137, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: summitLogo, width: "140px" },
    mainColor: "#4D4D4D",
    membershipUrl: "https://payment.savrm.org/",
    allianceName: "Summit STRA",
  },
  [STEAMBOATSPRINGS_SCOPE_DEV]: {
    backgroundConfig: {
      background:
      "linear-gradient(180deg, rgba(120, 129, 137, 0) 0%, rgba(77,77,77, 0.8) 100%), url(https://steamboatsprings.net/ImageRepository/Document?documentID=18610) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(120, 129, 137, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: steamboatLogo, width: "114px" },
    mainColor: "#96A58B",
    membershipUrl: "https://steamboatspringscpa.org/",
    allianceName: "Steamboat Springs",
  },
  [STEAMBOATSPRINGS_SCOPE]: {
    backgroundConfig: {
      background:
      "linear-gradient(180deg, rgba(120, 129, 137, 0) 0%, rgba(77,77,77, 0.8) 100%), url(https://steamboatsprings.net/ImageRepository/Document?documentID=18610) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(120, 129, 137, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: steamboatLogo, width: "114px" },
    mainColor: "#96A58B",
    membershipUrl: "https://steamboatspringscpa.org/",
    allianceName: "Steamboat Springs",
  },
  [AZRT_SCOPE_DEV]: {
    backgroundConfig: {
      background:
        "linear-gradient(180deg, rgba(37,98,112, 0.4) 100%, rgba(37,98,112, 0.1) 0%), url(https://azrtr.org/wp-content/uploads/2021/01/gautier-salles-uffQnKuJ-hc-unsplash-1.jpg) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(37,98,112, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: azrtLogo, width: "220px" },
    mainColor: "#fdb924",
    membershipUrl: "https://azrtr.org/",
    allianceName: "Arizona",
  },
  [AZRT_SCOPE]: {
    backgroundConfig: {
      background:
        "linear-gradient(180deg, rgba(37,98,112, 0.4) 100%, rgba(37,98,112, 0.1) 0%), url(https://azrtr.org/wp-content/uploads/2021/01/gautier-salles-uffQnKuJ-hc-unsplash-1.jpg) no-repeat center fixed",
      backgroundSize: "cover",
    },
    inputsConfig: {
      background: "rgba(37,98,112, 0.55)",
      borderRadius: "4px",
      color: "#fff",
      "&:hover": {
        borderColor: "#fff",
      },
      WebkitTextFillColor: "white",
    },
    logo: { img: azrtLogo, width: "220px" },
    mainColor: "#fdb924",
    membershipUrl: "https://azrtr.org/",
    allianceName: "Arizona",
  },
} as const;
