import { memo } from "react";
import { defaultKcProps, KcContextBase } from "keycloakify";
import { Login } from "./components/Login";
import LoginOtp from "keycloakify/lib/components/LoginOtp";
import Terms from "keycloakify/lib/components/Terms";
import Register from "keycloakify/lib/components/Register";
import LoginIdpLinkConfirm from "keycloakify/lib/components/LoginIdpLinkConfirm";
import { LoginResetPassword } from "./components/LoginResetPassword";
import LoginVerifyEmail from "keycloakify/lib/components/LoginVerifyEmail";

import LoginUpdateProfile from "keycloakify/lib/components/LoginUpdateProfile";
import { LoginUpdatePassword } from "components/LoginUpdatePassword";
import { Error } from "components/Error";
import { useI18n } from "i18n";
import LogoutConfirm from "components/LogoutConfirm";
import Info from "components/Info";
import { LoginPageExpired } from "components/LoginPageExpired";

export const KcApp = memo(({ kcContext }: { kcContext: KcContextBase }) => {
  const i18n = useI18n({
    kcContext,
  });

  //NOTE: Locales not yet downloaded
  if (i18n === null) {
    return null;
  }

  const kcProps = {
    i18n,
    ...defaultKcProps,
  };
  switch (kcContext.pageId) {
    case "login.ftl":
      return <Login {...{ kcContext, ...kcProps }} />;
    case "register.ftl":
      return <Register {...{ kcContext, ...kcProps }} />;
    case "terms.ftl":
      return <Terms {...{ kcContext, ...kcProps }} />;
    case "info.ftl":
      return <Info {...{ kcContext, ...kcProps }} />;
    case "error.ftl":
      return <Error {...{ kcContext, ...kcProps }} />;
    case "login-reset-password.ftl":
      return <LoginResetPassword {...{ kcContext, ...kcProps }} />;
    case "login-verify-email.ftl":
      return <LoginVerifyEmail {...{ kcContext, ...kcProps }} />;
    case "login-otp.ftl":
      return <LoginOtp {...{ kcContext, ...kcProps }} />;
    case "login-update-profile.ftl":
      return <LoginUpdateProfile {...{ kcContext, ...kcProps }} />;
    case "login-idp-link-confirm.ftl":
      return <LoginIdpLinkConfirm {...{ kcContext, ...kcProps }} />;
    case "login-update-password.ftl":
      return <LoginUpdatePassword {...{ kcContext, ...kcProps }} />;
    case "login-page-expired.ftl":
      return <LoginPageExpired {...{ kcContext, ...kcProps }} />;
    case "logout-confirm.ftl":
      return <LogoutConfirm {...{ kcContext, ...kcProps }} />;
    default:
      return null;
  }
});
