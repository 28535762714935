import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { ALLIANCE_CONFIG } from "constants/scopeMapping";
import { I18n } from "i18n";
import { KcContextBase, KcProps } from "keycloakify";
import { memo } from "react";
import Template from "../Template";

export const Error = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContextBase.Error; i18n: I18n } & KcProps) => {
    const { msg } = i18n;
    const { message, client } = kcContext;
    const scope = localStorage.getItem("scope");
    const defaultJoinUrl = window.location.host.includes("umbrellait.tech")
      ? "https://rr-dev-join.umbrellait.tech"
      : "https://join.rentresponsibly.org";
    const allianceName =
      scope && Object.keys(ALLIANCE_CONFIG).includes(scope)
        ? ALLIANCE_CONFIG[scope as keyof typeof ALLIANCE_CONFIG].allianceName
        : "Rent Responsibly 2.0";
    // hard code checking string to resolve proper message. TODO: find out a better way
    const summary =
      message && message.summary.match(/authenticated with identity provider/g)
        ? msg("federatedIdentityUnavailableMessage", allianceName)
        : message.summary;
    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={true}
        displayMessage={false}
        headerNode={msg("errorTitle")}
        formNode={
          <Box
            fontSize={"1.5rem"}
            fontWeight={"700"}
            display={"flex"}
            justifyContent={"center"}
            className="errorBlock">
            <Box className="instruction">{summary}</Box>
            {client !== undefined && (
              <LoadingButton
                type="button"
                href={defaultJoinUrl + "/" + scope}
                variant="contained"
                className="backHomeButton"
                color="primary">
                {msg("backToApplication")}
              </LoadingButton>
            )}
          </Box>
        }
      />
    );
  }
);
