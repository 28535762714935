import { memo } from "react";
import Template from "../Template";

import type { KcContextBase, KcProps } from "keycloakify";
import { Box, Link, Stack, Typography } from "@mui/material";
import { I18n } from "i18n";

export const LoginPageExpired = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContextBase.LoginPageExpired; i18n: I18n } & KcProps) => {
    const { msg } = i18n;
    const { url } = kcContext;

    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={false}
        displayMessage={false}
        headerNode={msg("pageExpiredTitle")}
        formNode={
          <Stack spacing={2}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography mr={1}>
                {msg("pageExpiredMsg1")}
              </Typography>
              <Link href={url.loginRestartFlowUrl} underline="none">
                {msg("doClickHere")}
              </Link>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography mr={1}>
                {msg("pageExpiredMsg2")}
              </Typography>
              <Link href={url.loginAction} underline="none">
                {msg("doClickHere")}
              </Link>
            </Box>
          </Stack>
        }
      />
    );
  }
);
