import { memo } from "react";
import { KcProps, KcContextBase } from "keycloakify";
import Template from "../Template";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { ALLIANCE_CONFIG } from "constants/scopeMapping";
import { I18n } from "i18n";

export const LoginResetPassword = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContextBase.LoginResetPassword; i18n: I18n } & KcProps) => {
    const { msg, msgStr } = i18n;
    const { url, realm, auth, messagesPerField } = kcContext;
    const scope = localStorage.getItem("scope");
    const isScopePresented =
      scope && Object.keys(ALLIANCE_CONFIG).includes(scope);
    const allianceInputsConfig =
      isScopePresented
        ? ALLIANCE_CONFIG[scope as keyof typeof ALLIANCE_CONFIG].inputsConfig
        : {
            background: "white",
            borderRadius: "4px",
            color: "black",
            "&:hover": {
              borderColor: "black",
            },
            WebkitTextFillColor: "black",
          };

    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={false}
        headerNode={msg("emailForgotTitle")}
        displayMessage={!messagesPerField.existsError("username")}
        displayInfo={true}
        formNode={
          <Box>
            <Box
              component="form"
              id="kc-form-login"
              action={url.loginAction}
              method="post">
              <Stack spacing={2}>
                <Typography textAlign="center">
                  {msg("emailInstruction")}
                </Typography>

                {/* Username or E-mail */}
                <TextField
                  id="username"
                  name="username"
                  label={
                    !realm.loginWithEmailAllowed
                      ? msg("username")
                      : !realm.registrationEmailAsUsername
                      ? msg("usernameOrEmail")
                      : msg("email")
                  }
                  autoComplete="email"
                  margin="normal"
                  type="text"
                  fullWidth
                  autoFocus
                  required
                  error={messagesPerField.existsError("username")}
                  helperText={
                    messagesPerField.existsError("username") &&
                    messagesPerField.get("username")
                  }
                  defaultValue={
                    auth !== undefined && auth.showUsername
                      ? auth.attemptedUsername
                      : undefined
                  }
                  InputLabelProps={{
                    style: {
                      color: isScopePresented ? "#fff" : "black",
                      fontSize: "18px",
                    },
                  }}
                  InputProps={{ style: allianceInputsConfig }}
                />

                {/* doSumit */}
                <Box display="flex" justifyContent="end">
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      color: "#fff",
                      textTransform: "unset",
                      fontWeight: "600",
                    }}>
                    {msg("doSubmit")}
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Box>
        }
        infoNode={
          <Box id="kc-registration" display="flex" justifyContent="start">
            <Button
              href={url.loginUrl}
              style={{
                color: "#fff",
              }}>
              {msg("backToLogin")}
            </Button>
          </Box>
        }
      />
    );
  }
);
