import { ChangeEvent, memo, MouseEvent, useState } from "react";
import { KcProps, KcContextBase } from "keycloakify";
import Template from "../Template";
import { ALLIANCE_CONFIG } from "constants/scopeMapping";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useConstCallback } from "powerhooks";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { I18n } from "i18n";

export const LoginUpdatePassword = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: {
    kcContext: KcContextBase.LoginUpdatePassword;
    i18n: I18n;
  } & KcProps) => {
    const { msg } = i18n;

    const { url } = kcContext;

    const [values, setValues] = useState({
      "password-new": "",
      "password-confirm": "",
    });

    const [isPasswordVisible, setPasswordIsVisible] = useState(false);

    const handleClickShowPassword = () => {
      setPasswordIsVisible(!isPasswordVisible);
    };

    const handleMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

    const onSubmit = useConstCallback(() => {
      setIsSubmitButtonDisabled(true);

      return true;
    });

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const scope = localStorage.getItem("scope");

    const isScopePresented =
      scope && Object.keys(ALLIANCE_CONFIG).includes(scope);
    const allianceInputsConfig = isScopePresented
      ? ALLIANCE_CONFIG[scope as keyof typeof ALLIANCE_CONFIG].inputsConfig
      : {
          background: "white",
          borderRadius: "4px",
          color: "black",
          "&:hover": {
            borderColor: "black",
          },
          WebkitTextFillColor: "black",
        };

    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={false}
        headerNode={msg("updatePasswordTitle")}
        formNode={
          <Box>
            <Box
              component="form"
              id="kc-form-login"
              onSubmit={onSubmit}
              action={url.loginAction}
              method="post">
              <Stack spacing={2}>
                {/* New Password */}
                <TextField
                  id="password-new"
                  name="password-new"
                  placeholder="Your new password"
                  label="Your new password"
                  margin="normal"
                  fullWidth
                  required
                  type={isPasswordVisible ? "text" : "password"}
                  value={values["password-new"]}
                  onChange={onChange}
                  autoComplete="new-password"
                  InputLabelProps={{
                    style: {
                      color: isScopePresented ? "#fff" : "black",
                      fontSize: "18px",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDown}>
                          {isPasswordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: allianceInputsConfig,
                  }}
                  {...props}
                />
                {/* Password Confirm */}
                <TextField
                  id="password-confirm"
                  name="password-confirm"
                  autoComplete="new-password"
                  placeholder="Confirm your new password"
                  label="Confirm your new password"
                  margin="normal"
                  fullWidth
                  required
                  type={isPasswordVisible ? "text" : "password"}
                  value={values["password-confirm"]}
                  onChange={onChange}
                  InputLabelProps={{
                    style: {
                      color: isScopePresented ? "#fff" : "black",
                      fontSize: "18px",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDown}>
                          {isPasswordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: allianceInputsConfig,
                  }}
                  {...props}
                />

                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  loading={isSubmitButtonDisabled}
                  disabled={isSubmitButtonDisabled}
                  style={{
                    color: "#fff",
                    textTransform: "unset",
                    fontWeight: "600",
                  }}>
                  {msg("doSubmit")}
                </LoadingButton>
              </Stack>
            </Box>
          </Box>
        }
      />
    );
  }
);
