import React, { memo } from "react";
import Template from "../Template";
import type { TemplateProps } from "../Template";
import { KcProps, KcContextBase } from "keycloakify";
import { assert } from "keycloakify/lib/tools/assert";
import type { I18n } from "../../i18n";
import { Box, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export type InfoProps = KcProps & {
  kcContext: KcContextBase.Info;
  i18n: I18n;
  doFetchDefaultThemeResources?: boolean;
  Template?: (props: TemplateProps) => JSX.Element | null;
};

const Info = memo((props: InfoProps) => {
  const {
    kcContext,
    i18n,
    doFetchDefaultThemeResources = true,
    ...kcProps
  } = props;

  const { msgStr, msg } = i18n;

  assert(kcContext.message !== undefined);

  const {
    messageHeader,
    message,
    requiredActions,
    skipLink,
    pageRedirectUri,
    actionUri,
    client,
  } = kcContext;

  return (
    <Template
      {...{ kcContext, i18n, doFetchDefaultThemeResources, ...kcProps }}
      displayMessage={false}
      headerNode={
        messageHeader !== undefined ? (
          <>{messageHeader}</>
        ) : (
          <>{message.summary}</>
        )
      }
      formNode={
        <div id="kc-info-message">
          <Box
            className="instruction"
            style={{ fontSize: "24px", marginBottom: "20px" }}>
            {message.summary}

            {requiredActions !== undefined && (
              <b>
                {requiredActions
                  .map((requiredAction) =>
                    msgStr(`requiredAction.${requiredAction}` as const)
                  )
                  .join(",")}
              </b>
            )}
          </Box>
          {!skipLink && pageRedirectUri !== undefined ? (
            <Box textAlign={"center"}>
              <LoadingButton
                type="button"
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  height: "48px",
                  marginTop: "24px",
                  textAlign: "center",
                }}
                className="backHomeButton"
                color="primary"
                variant="contained"
                href={pageRedirectUri}>
                {msg("backToApplication")}
              </LoadingButton>
            </Box>
          ) : actionUri !== undefined ? (
            <Box textAlign={"center"}>
              <LoadingButton
                type="button"
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  height: "48px",
                  marginTop: "24px",
                  textAlign: "center",
                }}
                color="primary"
                variant="contained"
                href={actionUri}>
                {msg("proceedWithAction")}
              </LoadingButton>
            </Box>
          ) : (
            client.baseUrl !== undefined && (
              <Box textAlign={"center"}>
                <LoadingButton
                  type="button"
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    height: "48px",
                    marginTop: "24px",
                    textAlign: "center",
                  }}
                  color="primary"
                  variant="contained"
                  href={client.baseUrl}>
                  {msg("backToApplication")}
                </LoadingButton>
              </Box>
            )
          )}
        </div>
      }
    />
  );
});

export default Info;
