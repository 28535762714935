import { useReducer, useEffect, memo } from "react";
import type { ReactNode } from "react";
import type { KcContextBase } from "keycloakify/lib/getKcContext/KcContextBase";
import { headInsert } from "keycloakify/lib/tools/headInsert";
import { pathJoin } from "keycloakify/bin/tools/pathJoin";
import { useConstCallback } from "powerhooks/useConstCallback";
import type { KcTemplateProps } from "keycloakify/lib/index";
import { clsx } from "keycloakify/lib/tools/clsx";
import type { I18n } from "keycloakify/lib/i18n";
import { Container, Stack, Box, Alert, Button } from "@mui/material";
import { ALLIANCE_CONFIG } from "constants/scopeMapping";
import { WARNING, getStatus } from "utils/status";
import DefaultLogo from "../../assets/images/rent-responsibly-logo.svg";

export type TemplateProps = {
  displayInfo?: boolean;
  displayMessage?: boolean;
  displayRequiredFields?: boolean;
  displayWide?: boolean;
  showAnotherWayIfPresent?: boolean;
  headerNode: ReactNode;
  showUsernameNode?: ReactNode;
  formNode: ReactNode;
  infoNode?: ReactNode;
  /** If you write your own page you probably want
   * to avoid pulling the default theme assets.
   */
  doFetchDefaultThemeResources: boolean;
} & { kcContext: KcContextBase; i18n: I18n } & KcTemplateProps;

const Template = memo((props: TemplateProps) => {
  const {
    displayInfo = false,
    displayMessage = true,
    showAnotherWayIfPresent = true,
    formNode,
    infoNode = null,
    kcContext,
    i18n,
    doFetchDefaultThemeResources,
    headerNode,
  } = props;
  const scope = localStorage.getItem("scope");
  const isScopePresented =
    scope && Object.keys(ALLIANCE_CONFIG).includes(scope);
  const allianceLogo = isScopePresented
    ? ALLIANCE_CONFIG[scope as keyof typeof ALLIANCE_CONFIG].logo
    : { img: DefaultLogo, width: "114px" };
  const { msg, changeLocale } = i18n;

  const onTryAnotherWayClick = useConstCallback(
    () => (
      document.forms["kc-select-try-another-way-form" as never].submit(), false
    )
  );

  const { url, message, isAppInitiatedAction } = kcContext;
  console.log(displayMessage, message, isAppInitiatedAction);

  const [isExtraCssLoaded, setExtraCssLoaded] = useReducer(() => true, false);

  useEffect(() => {
    if (!doFetchDefaultThemeResources) {
      setExtraCssLoaded();
      return;
    }

    let isUnmounted = false;
    const cleanups: (() => void)[] = [];

    const toArr = (x: string | readonly string[] | undefined) =>
      typeof x === "string" ? x.split(" ") : x ?? [];

    Promise.all(
      [
        ...toArr(props.stylesCommon).map((relativePath) =>
          pathJoin(url.resourcesCommonPath, relativePath)
        ),
        ...toArr(props.styles).map((relativePath) =>
          pathJoin(url.resourcesPath, relativePath)
        ),
      ]
        .reverse()
        .map((href) =>
          headInsert({
            type: "css",
            href,
            position: "prepend",
          })
        )
    ).then(() => {
      if (isUnmounted) {
        return;
      }

      setExtraCssLoaded();
    });

    toArr(props.scripts).forEach((relativePath) =>
      headInsert({
        type: "javascript",
        src: pathJoin(url.resourcesPath, relativePath),
      })
    );

    if (props.kcHtmlClass !== undefined) {
      const htmlClassList = document.getElementsByTagName("html")[0].classList;

      const tokens = clsx(props.kcHtmlClass).split(" ");

      htmlClassList.add(...tokens);

      cleanups.push(() => htmlClassList.remove(...tokens));
    }

    return () => {
      isUnmounted = true;

      cleanups.forEach((f) => f());
    };
  }, [props.kcHtmlClass]);

  if (!isExtraCssLoaded) {
    return null;
  }

  return (
    <Container
      fixed
      sx={{ paddingY: 4, maxWidth: "560px" }}
      style={{
        borderRadius: "24px",
        maxWidth: "560px",
        backgroundColor: isScopePresented ? "unset" : "rgb(255, 255, 255)",
      }}>
      <Stack spacing={2}>
        {/* Logo */}
        <Box my={2} display={"flex"} justifyContent={"center"}>
          <img src={allianceLogo.img} alt="logo" width={allianceLogo.width} />
        </Box>
        <Box
          fontSize={"1.5rem"}
          fontWeight={"700"}
          display={"flex"}
          justifyContent={"center"}
          style={{
            color: isScopePresented ? "#fff" : "black",
            marginBottom: "10px",
          }}>
          {headerNode}
        </Box>
        {/* displayMessage */}
        <Box my={2} display={"flex"} justifyContent={"center"}>
          {/* App-initiated actions should not see warning messages about the need to complete the action during login. */}
          {displayMessage &&
            message !== undefined &&
            (message.type !== "warning" || !isAppInitiatedAction) && (
              <div className={clsx("alert", `alert-${message.type}`)}>
                {message.type === "success" && (
                  <span className={clsx(props.kcFeedbackSuccessIcon)}></span>
                )}
                {message.type === "warning" && (
                  <span className={clsx(props.kcFeedbackWarningIcon)}></span>
                )}
                {message.type === "error" && (
                  <span className={clsx(props.kcFeedbackErrorIcon)}></span>
                )}
                {message.type === "info" && (
                  <span className={clsx(props.kcFeedbackInfoIcon)}></span>
                )}
                <span
                  className="kc-feedback-text"
                  style={{ color: message.type === "error" ? "red" : "" }}
                  dangerouslySetInnerHTML={{
                    __html: message.summary,
                  }}
                />
              </div>
            )}
        </Box>
        {/* formNode */}
        {formNode}

        {/* try-another-way */}
        {kcContext.auth?.showTryAnotherWayLink && showAnotherWayIfPresent && (
          <Box
            id="kc-select-try-another-way-form"
            component="form"
            method="post"
            action={kcContext.url.loginAction}>
            <input name="tryAnotherWay" type="hidden" value="on" />
            <Button
              id="try-another-way"
              variant="contained"
              fullWidth
              onClick={onTryAnotherWayClick}>
              {msg("doTryAnotherWay")}
            </Button>
          </Box>
        )}

        {/* infoNode */}
        {displayInfo && infoNode}

        {/* Locale
        {realm.internationalizationEnabled &&
          (assert(locale !== undefined), true) &&
          locale.supported.length > 1 && (
            <Box display="flex" justifyContent="end">
              <IconButton
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget);
                }}>
                <Language />
              </IconButton>
              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                  setAnchorEl(null);
                }}>
                {locale?.supported.map(({ languageTag }) => (
                  <MenuItem
                    key={languageTag}
                    onClick={onChangeLanguageClickFactory(languageTag)}>
                    {getKcLanguageTagLabel(languageTag)}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )} */}
      </Stack>
    </Container>
  );
});

export default Template;
