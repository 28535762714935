import React from "react";
import { defaultKcProps, getKcContext } from "keycloakify";
import { KcApp } from "./KcApp";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { ALLIANCE_CONFIG } from "./constants/scopeMapping";
import "./index.scss";
import { deleteCookie, getCookie, setCookie } from "utils/cookie";

// const { kcContext } = getKcContext();
const { kcContext } = getKcContext({ mockPageId: "login.ftl" });
// get the redirect_uri from search params
const redirectUri = new URL(document.location.toString()).searchParams.get(
  "redirect_uri"
);
// setting scope cookie via searchParams alliance param
if (redirectUri && new URL(redirectUri).searchParams.get("alliance")) {
  deleteCookie("scope");
  setCookie("scope", new URL(redirectUri).searchParams.get("alliance") ?? "", {
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    samesite: "Lax",
    domain:
      process.env.NODE_ENV === "development"
        ? undefined
        : "." + document.location.host.split(".").slice(1).join("."),
  });
}
// setting scope from subdomain(alliance)
let scope = redirectUri ? new URL(redirectUri).hostname.split(".")[0] : null;

// setting scope with prior to cookies. positioning does matter
localStorage.setItem(
  "scope",
  getCookie("scope") ?? scope ?? localStorage.getItem("scope") ?? ""
);
scope = localStorage.getItem("scope");
const isScopePresented = scope && Object.keys(ALLIANCE_CONFIG).includes(scope);
const allianceBackgroundConfig = isScopePresented
  ? ALLIANCE_CONFIG[scope as keyof typeof ALLIANCE_CONFIG].backgroundConfig
  : { backgroundColor: "#5A49B8" };

const theme = createTheme({
  typography: {
    fontFamily:
      "'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
  },
  palette: {
    primary: {
      main: isScopePresented
        ? ALLIANCE_CONFIG[scope as keyof typeof ALLIANCE_CONFIG].mainColor
        : "#5A49B8",
    },
    text: { primary: isScopePresented ? "#fff" : "#black" },
    action: { hover: "#a6a6a6" },
    divider: "#E0E0E0",
  },
});
const App: React.FC = () => {
  if (kcContext === undefined) throw new Error("kcContext is undefined.");

  return (
    <Box className="root" sx={allianceBackgroundConfig}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <KcApp
          kcContext={kcContext}
          {...{
            ...defaultKcProps,
          }}
        />
      </ThemeProvider>
    </Box>
  );
};

export default App;
