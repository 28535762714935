import React, { FormEvent, memo, useState } from "react";
import { clsx } from "keycloakify/lib/tools/clsx";
import Template, { TemplateProps } from "../Template";
import { KcProps, KcContextBase } from "keycloakify";
import { useConstCallback } from "powerhooks";
import { I18n } from "i18n";
import { ALLIANCE_CONFIG } from "constants/scopeMapping";
import { LoadingButton } from "@mui/lab";
import { Box, Link } from "@mui/material";
import { LinkRounded } from "@mui/icons-material";

export type LogoutConfirmProps = KcProps & {
  kcContext: KcContextBase.LogoutConfirm;
  i18n: I18n;
  doFetchDefaultThemeResources?: boolean;
  Template?: (props: TemplateProps) => JSX.Element | null;
};

const LogoutConfirm = memo((props: LogoutConfirmProps) => {
  const {
    kcContext,
    i18n,
    doFetchDefaultThemeResources = true,
    ...kcProps
  } = props;
  const scope = localStorage.getItem("scope");
  const isScopePresented =
    scope && Object.keys(ALLIANCE_CONFIG).includes(scope);
  const { url, client, logoutConfirm } = kcContext;
  const [isLogoutButtonDisabled, setIsLogoutButtonDisabled] = useState(false);
  const onLogoutClick = useConstCallback(() => {
    setIsLogoutButtonDisabled(true);
    const defaultJoinUrl = window.location.host.includes("umbrellait.tech")
      ? "https://rr-dev-join.umbrellait.tech"
      : "https://join.rentresponsibly.org";
    // we assume that logout confirm appears when the login state is somehow staled.
    // We just redirect user to the WP site so he could Log In once more.
    window.location.replace(
      isScopePresented
        ? ALLIANCE_CONFIG[scope as keyof typeof ALLIANCE_CONFIG].membershipUrl
        : defaultJoinUrl
    );
    return true;
  });

  const { msg, msgStr } = i18n;

  return (
    <Template
      {...{ kcContext, i18n, doFetchDefaultThemeResources, ...kcProps }}
      displayMessage={false}
      headerNode={msg("logoutConfirmTitle")}
      formNode={
        <>
          <div id="kc-logout-confirm" className="content-area">
            <p className="instruction">{msg("logoutConfirmHeader")}</p>
            <Box component="form" className="form-actions">
              <div className={clsx(kcProps.kcFormGroupClass)}>
                <div id="kc-form-options">
                  <div
                    className={clsx(kcProps.kcFormOptionsWrapperClass)}></div>
                </div>
                <LoadingButton
                  type="button"
                  onClick={onLogoutClick}
                  fullWidth
                  variant="contained"
                  loading={isLogoutButtonDisabled}
                  disabled={isLogoutButtonDisabled}
                  style={{
                    color: "#fff",
                    textTransform: "unset",
                    fontWeight: "600",
                    fontSize: "18px",
                    height: "48px",
                    marginTop: "24px",
                  }}>
                  {msgStr("doLogout")}
                </LoadingButton>
              </div>
            </Box>
            {!logoutConfirm.skipLink && client.baseUrl && (
              <Box textAlign={"center"}>
                <Link
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    height: "48px",
                    marginTop: "24px",
                    textAlign: "center",
                  }}
                  href={client.baseUrl}>
                  Back
                </Link>
              </Box>
            )}
          </div>
        </>
      }
    />
  );
});

export default LogoutConfirm;
